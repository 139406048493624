<template>
  <section class="my-orders-tab">
    <LcExpandable
      v-model="isActiveExpanded"
      class="pt-2xs"
      content-class="pt-md"
      :disabled="!showActiveList || isLoadingActive"
    >
      <template #header>{{ $t(`${activePath}.title`) }}</template>

      <div v-if="!isLoadingActive && totalActive === 0" class="pb-xs">
        <slot name="no-results" />
      </div>

      <LcRemoteList
        v-else-if="showActiveList"
        :button-text="$t(`${activePath}.button.loadmore`)"
        :count="countActive"
        hide-separator
        :loading="isLoadingActive"
        :total="totalActive"
        @fetch-more="
          fetchMoreActive({
            offset: countActive,
          })
        "
      >
        <slot v-if="resultActive" name="active" :result="resultActive" />
      </LcRemoteList>
    </LcExpandable>

    <LcSeparator
      v-if="showPastList || countActive < totalActive || !isActiveExpanded"
      class="my-md"
    />

    <LcExpandable
      v-if="showPastList"
      v-model="isPastExpanded"
      class="pt-2xs"
      content-class="pt-md"
      :disabled="isLoadingPast"
    >
      <template #header>{{ $t(`${pastPath}.title`) }}</template>

      <LcRemoteList
        :button-text="$t(`${pastPath}.button.loadmore`)"
        class="pt-xs"
        :count="countPast"
        hide-separator
        :loading="isLoadingPast"
        :total="totalPast"
        @fetch-more="
          fetchMorePast({
            offset: countPast,
          })
        "
      >
        <slot v-if="resultPast" name="past" :result="resultPast" />
      </LcRemoteList>
    </LcExpandable>

    <LcSeparator
      v-if="isScrollUpButtonVisible || countPast < totalPast || !isPastExpanded"
      class="my-md"
    />

    <div v-if="isScrollUpButtonVisible" class="flex w-full justify-center">
      <LcButton
        icon="arrow-up"
        size="medium"
        variant="secondary"
        @click="scrollTop(0)"
      />
    </div>
  </section>
</template>

<script generic="T extends InferredUseQueryReturn<T>" lang="ts" setup>
type FetchFn<T extends InferredUseQueryReturn<T>> = () => ReturnType<
  typeof useFetchMoreWrapper<T, number>
>

type MyOrdersTabProps<T extends InferredUseQueryReturn<T>> = {
  fetchActive: FetchFn<T>
  fetchPast: FetchFn<T>
  type: string
}

const props = defineProps<MyOrdersTabProps<T>>()

const { addToastError } = useToaster()
const { scrollTop } = useScrollUtils()

const isActiveExpanded = ref(true)
const isPastExpanded = ref(true)

const {
  count: countActive,
  fetchMore: fetchMoreActive,
  loading: isLoadingActive,
  onError: onErrorActive,
  result: resultActive,
  total: totalActive,
} = props.fetchActive()

const {
  count: countPast,
  fetchMore: fetchMorePast,
  loading: isLoadingPast,
  onError: onErrorPast,
  result: resultPast,
  total: totalPast,
} = props.fetchPast()

const activePath = computed(() => `${basePath.value}.active`)
const basePath = computed(() => `myorders.tabs.${props.type}.section`)
const isScrollUpButtonVisible = computed(() => {
  const visibleActiveOrders = isActiveExpanded.value ? totalActive.value : 0
  const visiblePastOrders = isPastExpanded.value ? totalPast.value : 0
  return visibleActiveOrders + visiblePastOrders >= 6
})
const pastPath = computed(() => `${basePath.value}.past`)
const sessionStorageKey = computed(
  () => `my_orders_scroll_position_${props.type}`,
)
const showActiveList = computed(
  () => isLoadingActive.value || countActive.value > 0,
)
const showPastList = computed(
  () => isLoadingActive.value || countPast.value > 0,
)

const onError = (error: unknown) => {
  addToastError({
    error,
    prefix: 'myorders.error',
    tracking: {
      topicKey: 'orders_load_failed',
    },
  })
}

onErrorActive(onError)
onErrorPast(onError)

onMounted(async () => {
  const savedScrollPosition = sessionStorage.getItem(sessionStorageKey.value)
  if (!savedScrollPosition) return
  if (isLoadingActive.value || isLoadingPast.value) {
    sessionStorage.removeItem(sessionStorageKey.value)
    return
  }

  await nextTick()
  window.scrollTo(0, parseInt(savedScrollPosition))
  sessionStorage.removeItem(sessionStorageKey.value)
})

onBeforeRouteLeave(({ path }) => {
  if (!path.match(`/my-orders/${props.type}/`) || !window?.scrollY) return
  sessionStorage.setItem(sessionStorageKey.value, String(window.scrollY))
})
</script>

<style lang="scss" scoped>
.my-orders-tab {
  min-height: 70vh;
}
</style>
