<template>
  <NoResults
    v-bind="{ illustration }"
    :alt="$t(`${translationBasePath}.alt`)"
    :button-text="
      isSelfExcluded ? undefined : $t(`${translationBasePath}.button.text`)
    "
    :headline="$t(`${translationBasePath}.headline`)"
    :subline="$t(`${translationBasePath}.subline`)"
    variant="outlined"
    @cta-click="navigateToPlayNow"
  />
</template>

<script lang="ts" setup>
const PLAY_NOW = '/play-now'

type MyOrdersNoResultsProps = {
  variant: 'scratchcard' | 'single-order' | 'subscription'
}

const props = defineProps<MyOrdersNoResultsProps>()

const { result } = useUserStatusQuery({ fetchPolicy: 'cache-first' })

const illustration = computed(() => {
  if (props.variant === 'single-order') return 'no-single-orders'
  if (props.variant === 'subscription') return 'no-subscriptions'
  return 'no-scratchcards'
})
const isSelfExcluded = computed(
  () => !!result.value?.player?.selfExclusion?.type,
)
const translationBasePath = computed(
  () => `myorders.tabs.${props.variant}.noresults`,
)

const navigateToPlayNow = () => {
  navigateTo(
    props.variant === 'scratchcard' ? `${PLAY_NOW}/scratchcards` : PLAY_NOW,
  )
}
</script>
